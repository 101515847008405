import {
    AccountService,
    getAppNameForProduct,
    Product,
} from "./AccountService";
import { AuthService } from "./AuthService";
import { Config } from "./ConfigurationService";
import { Service } from "./Service";

export class TopBarService extends Service {
    private $topBar: Element;
    private $appNav: Element;

    private $accountMenu: Element;
    private $userName: Element;

    constructor(
        config: Config,
        private authService: AuthService,
        private accountService: AccountService
    ) {
        super(config);
    }

    public initialize($topBar: Element) {
        this.$topBar = $topBar;
        this.$appNav = $topBar.querySelector("#\\24 appNav");

        // Initialize event handlers for account menu
        // TODO: We should also be able to remove the event listeners.
        this.$accountMenu = $topBar.querySelector("#\\24 accountMenu");
        this.$userName = $topBar.querySelector("#\\24 userName");

        const $userNameButton = document.getElementById("$userNameButton");
        const $userDetails = document.getElementById("$userDetails");
        const $logoutButton = document.getElementById("$logoutButton");

        $userNameButton.addEventListener("click", () => {
            $userDetails.classList.toggle("opened");
        });

        $logoutButton.addEventListener("click", () => {
            this.authService.logOut();
        });

        addEventListener("click", (event) => {
            const isInMenu = this.$accountMenu.contains(event.target as Node);
            if (!isInMenu) {
                $userDetails.classList.remove("opened");
            }
        });
    }

    private renderAppNavItem(product: Product) {
        let appName;
        try {
            appName = getAppNameForProduct(product);
        } catch (e) {
            // This happens if we manually added a non-standard product
            // definition to a user.
            // Right now, we do not render these in the top bar.
            console.warn(e);
            return "";
        }

        const isActive = appName === this.config.applicationName;
        return `
            <li class="shell-app-nav-entry shell-app-nav-entry--${
                isActive ? "active" : "inactive"
            }">
                <a
                    target="_blank" href="${product.url}"
                    class="shell-app-nav-link"
                >
                    <img class="shell-app-nav-icon"
                        width="300"
                        height="300"
                        alt="${product.title}"
                        title="${product.title}"
                        src="${product.icon}"
                    />
                    <span class="shell-app-title">${product.title}</span>
                </a>
            </li>
        `;
    }

    public async renderAppNav() {
        const products = await this.accountService.getAvailableProducts();

        const items = products
            .map((product) => this.renderAppNavItem(product))
            .join("");

        this.$appNav.innerHTML = `<ol class="shell-app-nav-list">${items}</ol>`;
    }

    public async renderAccountMenu() {
        const user = await this.authService.getAuthorizedUser();
        this.$userName.innerHTML = user.attributes.email;
    }
}
