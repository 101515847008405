import { Service } from "./Service";
import { Axios } from "axios";
import { Config } from "./ConfigurationService";
import { AuthService } from "./AuthService";
import { buildAxios } from "../helpers/axios-builder";

export type Tenant = {
    id: string;
    tenant_id: string;
    name: string;
};

export type Dashboard = {
    id: string;
    title: string;
    menu_title: string;
    order_number: number;
    icon?: string;
};

export type DashboardGroup = {
    id: string;
    tenant: Tenant;
    group_title: string;
    menu_title: string;
    icon?: string;
    dashboards: Dashboard[];
    order_number: number;
};

export type ContentPage = {
    id: string;
    title: string;
    menu_title: string;
    tenant?: Tenant;
    content: string;
};

export type ContentfulObject = Tenant | Dashboard | DashboardGroup | ContentPage;

export const compareDasboardGroups = (
    a: DashboardGroup,
    b: DashboardGroup
): number => {
    let order = a.order_number - b.order_number;
    if (order == 0) {
        const aFirst =
            a.menu_title.toLocaleLowerCase() < b.menu_title.toLocaleLowerCase();
        order = aFirst ? -1 : 1;
    }

    return order;
};

export class ContentfulService extends Service {
    private readonly axios: Axios;
    constructor(config: Config, auth: AuthService) {
        super(config);
        this.axios = buildAxios(auth, `https://${config.tytoApiDomain}`);
    }

    async getList<ContentfulObject>(
        entityName: string
    ): Promise<ContentfulObject[]> {
        const response = await this.axios.get(
            `/content-management/${entityName}/list`
        );
        return response.data.data;
    }

    async getDetails<ContentfulObject>(
        entityName: string,
        id: string
    ): Promise<ContentfulObject> {
        const response = await this.axios.get(
            `/content-management/${entityName}/detail/${id}`
        );
        return response.data.data;
    }

    // This is not a contentful call! It should be in its own service.
    async getDashboardConfig<ContentfulObject>(
        projectId: string,
        dashboardId: string
    ): Promise<ContentfulObject> {
        const response = await this.axios.get(
            `/gooddata/project/${projectId}/dashboard/${dashboardId}`
        );
        return response.data.data;
    }
}
