import * as snowplow from "@snowplow/browser-tracker";
import { AwsRum, AwsRumConfig } from "aws-rum-web";

import { ApplicationName, ApplicationStage, Config } from "./ConfigurationService";
import { Service } from "./Service";

import pkg from "../../package.json";

type RumConfig = {
  appId?: string,
  identityPoolId?: string,
  guestRoleArn?: string,
};

// These are set in the .env file of the current environment, and loaded at
// build time with dotenv-webpack. Note that this tool cannot deal with dynamic
// references to environment variables such as
// `process.env[appName + "_RUM_APPID"]`, so we reference all variables
// explicitly here.
const rumConfigs: { [app in ApplicationName]: RumConfig } = {
  eden: {
    appId: process.env.EDEN_RUM_APPID,
    identityPoolId: process.env.EDEN_RUM_IDENTITYPOOLID,
    guestRoleArn: process.env.EDEN_RUM_GUESTROLEARN,
  },
  heimdall: {
    appId: process.env.HEIMDALL_RUM_APPID,
    identityPoolId: process.env.HEIMDALL_RUM_IDENTITYPOOLID,
    guestRoleArn: process.env.HEIMDALL_RUM_GUESTROLEARN,
  },
  thuja: {
    appId: process.env.THUJA_RUM_APPID,
    identityPoolId: process.env.THUJA_RUM_IDENTITYPOOLID,
    guestRoleArn: process.env.THUJA_RUM_GUESTROLEARN,
  },
  tyto: {
    appId: process.env.TYTO_RUM_APPID,
    identityPoolId: process.env.TYTO_RUM_IDENTITYPOOLID,
    guestRoleArn: process.env.TYTO_RUM_GUESTROLEARN,
  },
};


export class TrackingService extends Service {
  rum?: AwsRum;

  constructor(config: Config) {
    super(config);

    snowplow.newTracker('cf', 'sp.spheremall.com', {
      // Example output:
      // Stage: Test, appName: Heimdall -> "heimdall.test.spheremall.com"
      // Stage: Prod, appName: Eden -> "eden.spheremall.com"
      appId: `${config.applicationName}.${config.stage == ApplicationStage.Production ? ("") : (config.stage.toLowerCase()+".")}spheremall.com`,
      cookieDomain: '.spheremall.com',
      stateStorageStrategy: 'localStorage',
      discoverRootDomain: true,
      respectDoNotTrack: true,
      contexts: {
        webPage: true
      }
    });


    const rumConfig = rumConfigs[config.applicationName];
    if (rumConfig.appId) {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
        telemetries: ["performance","errors","http"],
        allowCookies: true,
        enableXRay: false,
        guestRoleArn: "arn:aws:iam::735373629909:role/RUM-Monitor-eu-west-1-735373629909-3652145207561-Unauth",
        
        ...rumConfig,
      };
    
      this.rum = new AwsRum(rumConfig.appId, pkg.version, 'eu-west-1', config);
    }
  }

  setUserIdentity(id) {
    snowplow.setUserId(id);
  }

  trackPageView(url) {
    snowplow.trackPageView();
    this.rum?.recordPageView(url);
  }
}
