import { LifeCycles, registerApplication } from "single-spa";
import {
    ApplicationName,
    ApplicationStage,
    Config,
} from "./services/ConfigurationService";

type MfSpecification = {
    name: string;
    application: ApplicationName;
    activeWhen: string[];
    hostedIn?: ApplicationName;
};

const mfs: MfSpecification[] = [
    // Heimdall
    {
        name: "installation-mf",
        application: ApplicationName.Heimdall,
        activeWhen: ["/installation"],
    },
    {
        name: "url-mapping-mf",
        application: ApplicationName.Heimdall,
        activeWhen: ["/url-mapping"],
    },
    {
        name: "data-mapping-mf",
        application: ApplicationName.Heimdall,
        activeWhen: [
            "/data-sources",
            "/raw-data",
            "/field-exploration",
            "/field-mapping",
            "/value-exploration",
            "/value-mapping",
            "/review-mapping"
        ],
    },
    {
        name: "entities-mf",
        application: ApplicationName.Heimdall,
        activeWhen: ["/scopes", "/products"],
        hostedIn: ApplicationName.Eden
    },

    // Eden
    {
        name: "entities-mf",
        application: ApplicationName.Eden,
        activeWhen: [
            "/products",
            "/functional-names",
            "/brands",
            "/categories",
            "/entity-groups",
            "/attributes",
        ],
    },

    {
        name: "data-mapping-mf",
        application: ApplicationName.Eden,
        activeWhen: ["/consumers"],
        hostedIn: ApplicationName.Heimdall
    },
    

    // Tyto
    {
        name: "gooddata-ui-mf",
        application: ApplicationName.Tyto,
        activeWhen: ["/dashboards", "/gd-reports"],
    },
    {
        name: "reporting-mf",
        application: ApplicationName.Tyto,
        activeWhen: ["/reports", "/definitions"],
    },

    // Thuja
    {
        name: "entitygroups-mf",
        application: ApplicationName.Thuja,
        activeWhen: [
            "/products",
            "/entity-groups",
            "/:channel/products",
            "/:channel/entity-groups",
            "/:channel/filters-settings",
            "/publish-settings",
        ],
    },
    {
        name: "pma-mf",
        application: ApplicationName.Thuja,
        activeWhen: ["/actions", "/templates", "/campaigns", "/user-groups"],
    },
    {
        name: "emailpublisher-mf",
        application: ApplicationName.Thuja,
        activeWhen: ["/mailer"],
    },
];

export const initializeMfs = (config: Config) => {
    const relevantMfs = mfs.filter(
        ({ application }) => application === config.applicationName
    );

    const importMap: System.ImportMap = {
        imports: {},
    };

    const importOverrides: System.ImportMap = window["importMapOverrides"]
        ? window["importMapOverrides"].getOverrideMap()
        : { imports: {} };

    relevantMfs
        .filter(({ name }) => !(name in importOverrides.imports))
        .forEach(({ name, application, hostedIn }) => {
            const appDomain = (hostedIn || application).toLowerCase();
            importMap.imports[name] =
                config.stage === ApplicationStage.Production
                    ? `https://${name}.${appDomain}.spheremall.com/main.js`
                    : `https://${name}.${appDomain}.${config.stage.toLocaleLowerCase()}.spheremall.com/main.js`;
        });

    // it is needed for local development
    //importMap.imports['gooddata-ui-mf'] = 'http://localhost:8080/main.js';
    // importMap.imports['url-mapping-mf'] = 'http://localhost:4003/main.js';
    //importMap.imports['eden-entities-mf'] = 'http://localhost:4006/main.js';
    // importMap.imports['data-mapping-mf'] = 'http://localhost:4249/main.js';

    System.addImportMap(importMap);
    System.addImportMap(importOverrides);
    relevantMfs.forEach(({ name, activeWhen }) => {
        registerApplication({
            name,
            activeWhen,
            app: () => System.import<LifeCycles>(name),
        });
    });
};
