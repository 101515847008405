import { start } from "single-spa";
import * as snowplow from '@snowplow/browser-tracker';

import { AuthService } from "./services/AuthService";
import "./assets/main.scss";
import { ContentfulService } from "./services/ContentfulService";
import {
    ApplicationName,
    ApplicationStage,
    ConfigurationService,
} from "./services/ConfigurationService";
import { SidebarService } from "./services/SidebarService";
import { AccountService } from "./services/AccountService";
import { TopBarService } from "./services/TopBarService";
import { initializeMfs } from "./micro-front-ends";
import { TrackingService } from "./services/TrackingService";
import { ChannelService } from './services/ChannelService';
import { ConsumersService } from './services/ConsumersService';
import { ScopeService } from "./services/ScopeService";

const appName = process.env.APPLICATION_NAME
    ? (process.env.APPLICATION_NAME.toLowerCase() as ApplicationName)
    : ApplicationName.Tyto;

const appStage =
    (process.env.APPLICATION_STAGE as ApplicationStage) ||
    ApplicationStage.Test;


// Configuration
const configService = new ConfigurationService(appName, appStage);
const config = configService.getConfig();

initializeMfs(config);

// Dynamic elements
const $shell = document.getElementById("$shell");
const $sidebar = document.getElementById("$sidebar");
const $topBar = document.getElementById("$topBar");

configService.initializeTheme($shell);

// Initialize generic services services
const authService = new AuthService(config);
const trackingService = new TrackingService(config);
const contentfulService = new ContentfulService(config, authService);
const channelService = new ChannelService(config, authService);
const accountService = new AccountService(config, authService);
const consumersService = new ConsumersService(config, authService);
const scopeService = new ScopeService(config, authService);

// We expose the generic services on the global scope, for easy debugging and
// to share them with micro-front-ends. We will probably want to change how we
// share these services in the future.
window["authService"] = authService;
window["trackingService"] = trackingService;
window["contentfulService"] = contentfulService;
window["accountService"] = accountService;
window["consumersService"] = consumersService;
window["scopeService"] = scopeService;
window["config"] = config;

// Initialize shell-specific services
const sidebarService = new SidebarService(config, $sidebar, contentfulService, channelService);
const topBarService = new TopBarService(config, authService, accountService);

// Init shell
topBarService.initialize($topBar);
authService.init().then(async (isLoggedIn) => {
    if (!isLoggedIn) {
        authService.redirect(true);
    } else {
        topBarService.renderAppNav();
        topBarService.renderAccountMenu();

        sidebarService.initializeContent();

        // Set up tracking
        window.addEventListener("single-spa:routing-event", (e) => {
            trackingService.trackPageView(e["detail"].newUrl);
        });
        const user = await authService.getAuthorizedUser();
        trackingService.setUserIdentity(user.attributes.email);
    }
});

start();
