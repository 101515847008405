import { Axios } from "axios";
import { AuthService } from "../services/AuthService";

export const buildAxios = (auth: AuthService, domain: string) => {
    const axios = new Axios({
        baseURL: domain,
        headers: {
            "Content-Type": "application/json",
        },
        transformResponse: [
            function (data, headers) {
                // startsWith, because it can also contain a charset, eg:
                // `content-type": "application/json; charset=utf-8"`
                //
                // NOTE: I'm not sure why this is needed at all. Normally axios
                //       does parsing automatically, but for some reason it
                //       is not working.
                //       If anyone can fix this, that would be appreciated.
                if (headers["content-type"].startsWith("application/json")
                    && typeof data == "string") {
                        
                    return JSON.parse(data);
                }
                return data;
            },
        ],
    });

    axios.interceptors.request.use(async (config) => {
        const token = await auth.getToken();
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    });

    return axios;
};
