import { Axios } from "axios";
import { buildAxios } from "../helpers/axios-builder";
import { AuthService } from "./AuthService";
import { ApplicationName, Config } from "./ConfigurationService";
import { Service } from "./Service";

export type Product = {
    icon: string;
    id: number;
    orderNumber: number;
    title: string;
    url: string;
    visible: number;
};

export const getAppNameForProduct = (product: Product): ApplicationName => {
    const name = product.title.toLowerCase() as ApplicationName;
    const options = Object.values(ApplicationName);
    if (!options.includes(name)) {
        throw new Error(
            `Unknown product ${name}. Expected one of ${options.join(", ")}`
        );
    }

    return name;
};

export class AccountService extends Service {
    private readonly axios: Axios;

    constructor(config: Config, auth: AuthService) {
        super(config);

        this.axios = buildAxios(auth, `https://${config.accountsApiDomain}`);
    }

    public async getAvailableProducts(): Promise<Product[]> {
        type Response = {
            success: boolean;
            data: Product[];
        };
        const response = await this.axios.get<Response>(`/products/available`);
        return response.data.data;
    }
}
