import { Auth } from "aws-amplify";
import { Service } from "./Service";

export class AuthService extends Service {
    public async init() {
        this.initializeFromRedirect();

        const config = this.getAmplifyConfiguration();
        Auth.configure(config);

        /**
         * It will try to refresh access token if expired && refresh token is
         * valid.
         * @see https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#retrieve-current-session
         */
        const session = await Auth.currentSession().catch(() => null);

        return Boolean(session && session.isValid());
    }

    public redirect(logout: boolean = false): void {
        this.clearLocalStorage();

        const urlParams = new URLSearchParams();
        urlParams.set("redirect_uri", window.location.origin);
        if (logout) {
            urlParams.set("logout", "1");
        }

        window.location.href = `https://${
            this.config.authDomain
        }/login?${urlParams.toString()}`;
    }

    public async logOut() {
        this.redirect(true);
    }

    public async getAuthorizedUser() {
        return await Auth.currentAuthenticatedUser();
    }

    public async getToken() {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    }

    public getAmplifyConfiguration() {
        const config = new URLSearchParams(
            localStorage.getItem("CognitoConfig")
        );
        const requiredFields = ["poolId", "clientId", "domain"];
        if (!requiredFields.every((param) => config.has(param))) {
            return null;
        }

        const clientId = config.get("clientId");
        const poolId = config.get("poolId");
        const cognitoRegion = poolId.split("_")[0];
        const domain = config.get("domain");

        return {
            userPoolId: poolId,
            userPoolWebClientId: clientId,
            region: cognitoRegion,
            oauth: {
                domain: `${domain}.auth.${cognitoRegion}.amazoncognito.com`,
                scope: ["email", "openid"],
                redirectSignIn: window.location.origin,
                redirectSignOut: window.location.origin,
                responseType: "code",
            },
        };
    }

    /**
     * This does all the post-log in processing, like storing the cognito
     * session info in localStorage, and cleaning up the URL.
     */
    private initializeFromRedirect() {
        const params = new URL(window.location.href).searchParams;
        const requiredFields = [
            "userPool",
            "username",
            "idToken",
            "refreshToken",
        ];
        if (requiredFields.every((param) => params.has(param))) {
            window.history.pushState(
                {
                    path: window.location.origin,
                },
                "",
                window.location.origin
            );

            const userPool = params.get("userPool");
            const clientId = new URLSearchParams(userPool).get("clientId");
            const cognitoKey = `CognitoIdentityServiceProvider.${clientId}`;
            const username = params.get("username");

            localStorage.setItem("CognitoConfig", userPool);
            localStorage.setItem(`${cognitoKey}.LastAuthUser`, username);
            localStorage.setItem(
                `${cognitoKey}.${username}.idToken`,
                params.get("idToken")
            );
            localStorage.setItem(
                `${cognitoKey}.${username}.refreshToken`,
                params.get("refreshToken")
            );
        }
    }

    private clearLocalStorage(): void {
        localStorage.removeItem("CognitoConfig");
        Object.keys(localStorage).forEach((key) => {
            if (key.includes("CognitoIdentityServiceProvider")) {
                localStorage.removeItem(key);
            }
        });
    }
}
