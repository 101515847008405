import { Axios } from "axios";
import { buildAxios } from "../helpers/axios-builder";
import { AuthService } from "./AuthService";
import { Config } from "./ConfigurationService";
import { Service } from "./Service";

export type Slice = {
  offset: number,
  limit: number,
};

export type PagedMeta = Slice & {
  total: number,
};


export type ScopeSummary = {
  id: string,
  type: "scope",
  attributes: {
    name: string,
    owner: string,
    defaultVersion: string,
    type: string,
  },
};

export type ScopeDetails = any;{
  // access: 
}

export class ScopeService extends Service {
    private readonly axios: Axios;

    constructor(config: Config, auth: AuthService) {
        super(config);
        this.axios = buildAxios(auth, `https://${config.nucleusApiDomain}`);
    }

    public async listScopes(slice: Slice = { limit: 10, offset: 0 }): Promise<[ScopeSummary[], PagedMeta ]> {
      const response = await this.axios.get("/scopes", {
        params: slice
      });
      return [ response.data.data, response.data.meta ];
    }

    public async getScope(id: string): Promise<ScopeSummary[]> {
      const response = await this.axios.get(`/scopes/${id}`);
      return response.data.data;
    }


    public async getScopeDetails(id: string): Promise<ScopeDetails> {
      const [ access, entityCount, model ] = await Promise.all([
        await this.axios.get(`/scopes/${id}/access`),
        (await this.axios.get(`/scopes/${id}/entities`)).data.meta.total,
        (await this.axios.get(`/scopes/${id}/model`)).data,
      ]);

      return {
        access, entityCount, model
      };
    }

}
