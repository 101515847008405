import { Service } from "./Service";
import { Axios } from "axios";
import { Config } from "./ConfigurationService";
import { AuthService } from "./AuthService";
import { buildAxios } from "../helpers/axios-builder";

export type Channel = {
    id: string;
    type: string,
    attributes: {
        channel: string,
        tenant: string
    }
};

export class ChannelService extends Service {
    private readonly axios: Axios;
    constructor(config: Config, auth: AuthService) {
        super(config);
        this.axios = buildAxios(auth, `https://${config.thujaApiDomain}`);
    }

    async getList(): Promise<Channel[]> {
        const response = await this.axios.get(
            `/entities/channels`
        );
        return response.data.data;
    }
}
