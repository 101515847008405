import { Service } from "./Service";

export enum ApplicationName {
    Heimdall = "heimdall",
    Eden = "eden",
    Thuja = "thuja",
    Tyto = "tyto",

    // There's also Nucleus, Nash, Odin and Frigga, but these currently do not have front-ends.
}
export const validApplicationNames = Object.keys(ApplicationName);

export enum ApplicationStage {
    Development = "Dev",
    Test = "Test",
    Production = "Prod",
}
export const validApplicationStages = Object.keys(ApplicationStage);

export type Theme = {
    colors: {
        primary: string;
        topBar: string;
    };
};

export type Config = {
    authDomain: string;
    accountsApiDomain: string;
    thujaApiDomain: string;
    tytoApiDomain: string;
    heimdallApiDomain: string;
    edenApiDomain: string;
    nucleusApiDomain: string;

    applicationName: ApplicationName;
    stage: ApplicationStage;
};

export class ConfigurationService extends Service {
    private theme: Theme;

    constructor(applicationName: ApplicationName, stage: ApplicationStage) {
        if (!validApplicationNames.includes(applicationName)) {
            console.warn(
                `Unknown application name! Expected one of ${validApplicationNames.join()}, but got ${applicationName}`
            );
        }

        if (!validApplicationStages.includes(stage)) {
            console.warn(
                `Unknown application stage! Expected one of ${validApplicationStages.join()}, but got ${stage}`
            );
        }

        let urlInfix: string = "";

        switch (stage) {
            case ApplicationStage.Development:
            case ApplicationStage.Test:
                urlInfix = "." + stage.toLowerCase();
                break;

            case ApplicationStage.Production:
                break;

            default:
                const options = Object.values(ApplicationStage).join(", ");
                throw new Error(
                    `Unknown application stage ${stage}. Expected one of ${options}`
                );
        }

        const config: Config = {
            applicationName,
            stage,

            authDomain: stage == ApplicationStage.Production ? "auth.users.spheremall.com" : `auth.accounts${urlInfix}.spheremall.com`,
            accountsApiDomain: stage == ApplicationStage.Production ? "api.users.spheremall.com" : `api.accounts${urlInfix}.spheremall.com`,
            thujaApiDomain: `api.thuja${urlInfix}.spheremall.com`,
            tytoApiDomain: `api.tyto${urlInfix}.spheremall.com`,
            heimdallApiDomain: `api.heimdall${urlInfix}.spheremall.com`,
            edenApiDomain: `api.eden${urlInfix}.spheremall.com`,
            nucleusApiDomain: `api.nucleus${urlInfix}.spheremall.com`
        };
        super(config);
    }

    getConfig() {
        return this.config;
    }

    initializeTheme(rootElement: Element, classPrefix = "shell") {
        // rootElement.classList.add(
        //     `${classPrefix}--${this.config.applicationName}`
        // );

        const styles = getComputedStyle(rootElement);
        this.theme = {
            colors: {
                primary: styles.getPropertyValue("--color-primary"),
                topBar: styles.getPropertyValue("--color-top-bar"),
            },
        };

        return this.theme;
    }
}
