import { ApplicationName, ApplicationStage, Config } from "./ConfigurationService";
import {
    compareDasboardGroups,
    ContentfulService,
    ContentPage,
    DashboardGroup,
} from "./ContentfulService";
import { Service } from "./Service";
import { Channel, ChannelService } from "./ChannelService";
import { AuthService } from "./AuthService";

type BaseNavEntry = {
    icon: string;
    displayName: string;
    title?: string;
};
type NavGroup = BaseNavEntry & {
    children: NavEntry[];
};
type NavLink = BaseNavEntry & {
    url: string;
};
type NavEntry = NavGroup | NavLink;

export class SidebarService extends Service {
    private $button: Element;
    private $nav: Element;
    private $footer: Element;
    private $navGroups: Element[] = [];
    private $links: HTMLAnchorElement[] = [];
    private $activeLink: HTMLAnchorElement;
    private collapseSidebarEvent = new Event("collapseSidebar");

    constructor(
        config: Config,
        private $sidebar: Element,
        private contentful: ContentfulService,
        private channelService: ChannelService
    ) {
        super(config);

        // This is very tightly coupled with the template. Changes to the
        // template are likely to affect this. We could give these elements
        // their own IDs, which is more flexible -- however, some logic also
        // depends on the template structure.
        [this.$button, this.$nav, this.$footer] = Array.from(
            this.$sidebar.children
        );

        this.initializeEvents();
    }

    private initializeEvents() {
        this.$nav.addEventListener("mouseenter", (e) => {
            if (this.$sidebar.classList.contains("shell-sidebar--collapsed")) {
                window.dispatchEvent(this.collapseSidebarEvent);
            }
            setTimeout(() => {
                this.$sidebar.classList.add("shell-sidebar--hover");
            });
        });
        this.$footer.addEventListener("mouseenter", (e) => {
            if (this.$sidebar.classList.contains("shell-sidebar--collapsed")) {
                window.dispatchEvent(this.collapseSidebarEvent);
            }
            setTimeout(() => {
                this.$sidebar.classList.add("shell-sidebar--hover");
            });
        });
        this.$sidebar.addEventListener("mouseleave", (e) => {
            if (this.$sidebar.classList.contains("shell-sidebar--collapsed")) {
                window.dispatchEvent(this.collapseSidebarEvent);
            }
            setTimeout(() => {
                this.$sidebar.classList.remove("shell-sidebar--hover");
            });
        });

        this.$button.addEventListener("click", (e) => {
            window.dispatchEvent(this.collapseSidebarEvent);
            setTimeout(() => {
                this.$sidebar.classList.toggle("shell-sidebar--expanded");
                this.$sidebar.classList.toggle("shell-sidebar--collapsed");

                // Without this, the menu often stays open until you move your
                // mouse, which feels wrong.
                if (
                    this.$sidebar.classList.contains("shell-sidebar--collapsed")
                ) {
                    this.$sidebar.classList.remove("shell-sidebar--hover");
                }
            });
        });
    }

    private renderNavEntry(entry: NavEntry, depth: number = 0) {
        if ("url" in entry) {
            return `
                <li class="shell-sidebar-nav-entry shell-sidebar-nav-leaf shell-sidebar-nav-link--not-active">
                    <a
                        class="shell-sidebar-nav-line shell-sidebar-nav-link"
                        href="${entry.url}"
                        title=${entry.title || ""}
                    >
                        <span class="shell-sidebar-nav-icon mat-icon--filled">${
                            entry.icon
                        }</span>
                        <span class="shell-sidebar-nav-name">${
                            entry.displayName
                        }</span>
                    </a>
                </li>
          `;
        } else {
            return `
                <li class="shell-sidebar-nav-entry shell-sidebar-nav-group shell-sidebar-nav-group--collapsed">
                    <span class="shell-sidebar-nav-line">
                        <span class="shell-sidebar-nav-icon mat-icon--filled">
                            ${entry.icon}
                        </span>
                        <span class="shell-sidebar-nav-name">
                            ${entry.displayName}
                        </span>
                    </span>
                    <ul class="shell-sidebar-nav-child-list" style="--nav-depth: ${
                        depth + 1
                    }">
                        ${entry.children
                            .map((child) =>
                                this.renderNavEntry(child, depth + 1)
                            )
                            .join("")}
                    </ul>
                </li>
            `;
        }
    }

    private handleToggleGroup(event: MouseEvent) {
        event.stopPropagation();

        const $el = event.currentTarget as Element;
        SidebarService.toggleGroup($el);
    }

    private static toggleGroup($el: Element) {
        const isExpanding = $el.classList.contains(
            "shell-sidebar-nav-group--collapsed"
        );

        $el.classList.toggle("shell-sidebar-nav-group--expanded");
        $el.classList.toggle("shell-sidebar-nav-group--collapsed");

        const $childList = $el.querySelector("ul");
        let heightDelta = 0;
        if (!isExpanding) {
            heightDelta = -parseFloat($childList.style.maxHeight);
            $childList.style.maxHeight = "0";
        } else {
            const $children = $childList.querySelectorAll(":scope > li");
            if ($children.length) {
                const top = $children[0].getBoundingClientRect().top;
                const bottom =
                    $children[$children.length - 1].getBoundingClientRect()
                        .bottom;

                heightDelta = bottom - top;
                $childList.style.maxHeight = `${heightDelta}px`;
            }
        }

        for (
            let $parent = $el.parentElement;
            $parent;
            $parent = $parent.parentElement
        ) {
            if ($parent.classList.contains("shell-sidebar-nav-child-list")) {
                const currentHeight = parseFloat($parent.style.maxHeight);

                $parent.style.maxHeight = `${Math.max(
                    0,
                    currentHeight + heightDelta
                )}px`;
            }

            if ($parent.classList.contains("shell-sidebar-nav-root-list")) {
                break;
            }
        }
    }

    private static setActiveLink($link: HTMLAnchorElement) {
        document
            .querySelectorAll(".shell-sidebar-nav-link--active")
            .forEach(($el) =>
                $el.classList.replace(
                    "shell-sidebar-nav-link--active",
                    "shell-sidebar-nav-group--not-active"
                )
            );

        $link.parentElement.classList.replace(
            "shell-sidebar-nav-link--not-active",
            "shell-sidebar-nav-link--active"
        );
    }

    private followLink(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        SidebarService.setActiveLink(e.currentTarget as HTMLAnchorElement);
        window["singleSpaNavigate"](e);
    }

    public async initializeContent() {
        let navItems: NavEntry[] = [];

        switch (this.config.applicationName) {
            case ApplicationName.Tyto:
                const dashboardGroups = await this.contentful
                    .getList<DashboardGroup>("dashboardGroups")
                    .catch(() => [] as DashboardGroup[]);

                const contentPages = await this.contentful
                    .getList<ContentPage>("contentPages")
                    .catch(() => [] as ContentPage[]);

                dashboardGroups.sort(compareDasboardGroups);
                const dashboardNav: NavGroup[] = dashboardGroups.map(
                    (group) => ({
                        displayName: group.menu_title,
                        icon: group.icon || "space_dashboard",
                        children: group.dashboards.map((dashboard) => ({
                            displayName: dashboard.menu_title,
                            icon: dashboard.icon || "keyboard_arrow_right",
                            url: `/dashboards/${dashboard.id}`,
                        })),
                    })
                );

                const dda = dashboardNav.filter(
                    (group) => group.displayName == "Direct Data Access"
                );

                const dashboardsDefinitions = dashboardNav.filter((group) =>
                    group.displayName.endsWith("Definitions")
                );

                const definitions = [
                    ...dashboardsDefinitions.flatMap((group) => {
                        return group.children.map((item) => ({
                            ...item,
                            icon: group.icon,
                        }));
                    }),
                    ...contentPages.map((contentPage) => {
                        return {
                            displayName: contentPage.menu_title,
                            icon: "description",
                            url: `/definitions/${contentPage.id}`,
                        };
                    }),
                ];

                const specialDashboardGroups = [...dda, ...dashboardsDefinitions,];

                navItems = [
                    {
                        displayName: "Dashboards",
                        icon: "dashboard",
                        children: dashboardNav.filter(
                            (g) => !specialDashboardGroups.includes(g)
                        ),
                    },
                    ...dda,
                    {
                        displayName: "Reports",
                        icon: "assignment",
                        url: "/reports",
                    },
                    {
                        displayName: "More information",
                        icon: "live_help",
                        children: definitions,
                    },
                ];

                break;
            case ApplicationName.Heimdall:
                if (this.config.stage == ApplicationStage.Production) {
                    navItems = [
                        {
                            displayName: "Raw data sets",
                            icon: "dataset",
                            url: "/scopes",
                        }
                    ];
                } else {

                    navItems = [
                        // {
                        //     displayName: "Getting started",
                        //     icon: "integration_instructions",
                        //     url: "/installation",
                        // },
                        {
                            displayName: "Data Sources",
                            icon: "room_preferences",
                            url: "/data-sources",
                        },
                        // {
                        //     displayName: "Entities",
                        //     icon: "storage",
                        //     url: "/products",
                        // },
                        {
                            displayName: "Raw data",
                            icon: "raw_on",
                            url: "/raw-data",
                        },
                        {
                            displayName: "Data Mapping",
                            icon: "settings_input_composite",
                            children: [
                                {
                                    displayName: "Data Exploration",
                                    icon: "dataset",
                                    url: "/field-exploration",
                                },
                                {
                                    displayName: "Field Mapping",
                                    icon: "account_tree",
                                    url: "/field-mapping",
                                },
                                // {
                                //     displayName: "Value Exploration",
                                //     icon: "view_timeline",
                                //     url: "/value-exploration",
                                // },
                                {
                                    displayName: "Value Mapping",
                                    icon: "event_list",
                                    url: "/value-mapping",
                                },
                                {
                                    displayName: "Review",
                                    icon: "preview",
                                    url: "/review-mapping",
                                },
                                // {
                                //     displayName: "URL Mapping",
                                //     icon: "view_column",
                                //     url: "/url-mapping"
                                // }
                            ],
                        },
                    ];

                    const user = await (window["authService"] as AuthService).getAuthorizedUser()
                    if (user.attributes["custom:domain"].startsWith("spheremall-spheremall-")) {
                        navItems.splice(1, 0, {
                            displayName: "Scopes",
                            icon: "dataset",
                            url: "/scopes",
                        });
                    }
                }

                break;
            case ApplicationName.Eden:
                navItems = [
                    {
                        displayName: "Entities",
                        icon: "storage",
                        children: [
                            {
                                displayName: "Consumers",
                                icon: "people",
                                url: "/consumers",
                            },
                            {
                                displayName: "Products",
                                icon: "layers",
                                url: "/products",
                            },
                            // {
                            //     displayName: "Functional Names",
                            //     icon: "view_week",
                            //     url: "/functional-names",
                            // },
                            // {
                            //     displayName: "Brands",
                            //     icon: "loyalty",
                            //     url: "/brands",
                            // },
                            // {
                            //     displayName: "Categories",
                            //     icon: "category",
                            //     url: "/categories",
                            // },
                            // {
                            //     displayName: "Entity Groups",
                            //     icon: "store",
                            //     url: "/entity-groups",
                            // },
                        ],
                    },
                    // {
                    //     displayName: "Entities Structure",
                    //     icon: "view_carousel",
                    //     children: [
                    //         {
                    //             displayName: "Attributes",
                    //             icon: "bubble_chart",
                    //             url: "/attributes",
                    //         },
                    //     ],
                    // },
                ];
                break;
            case ApplicationName.Thuja:
                const channels = await this.channelService
                    .getList()
                    .catch(() => [] as Channel[]);

                const channelNav = channels.map((item) => ({
                    displayName:
                        item.attributes.channel.charAt(0).toUpperCase() +
                        item.attributes.channel.slice(1),
                    icon: "space_dashboard",
                    children: [
                        {
                            displayName: "Products",
                            icon: "layers",
                            url: `/${item.id}/products`,
                        },
                        {
                            displayName: "Entity Groups",
                            icon: "store",
                            url: `/${item.id}/entity-groups`,
                        },
                        {
                            displayName: "Filters/sorting settings",
                            icon: "filter_alt",
                            url: `/${item.id}/filters-settings`,
                        },
                    ],
                }));
                navItems = [
                    {
                        displayName: "Entities",
                        icon: "storage",
                        children: [
                            {
                                displayName: "Products",
                                icon: "layers",
                                url: "/products",
                            },
                            {
                                displayName: "Entity Groups",
                                icon: "store",
                                url: "/entity-groups",
                            },
                        ],
                    },
                    {
                        displayName: "Channels",
                        icon: "view_carousel",
                        children: channelNav,
                    },
                    {
                        displayName: "Publish settings",
                        icon: "tune",
                        url: "/publish-settings",
                    },
                    {
                        displayName: "Marketing Automation",
                        icon: "campaign",
                        children: [
                            {
                                displayName: "Actions",
                                icon: "wysiwyg",
                                url: "/actions",
                            },
                            {
                                displayName: "Templates",
                                icon: "view_list",
                                url: "/templates",
                            },
                            {
                                displayName: "Campaigns",
                                icon: "campaign",
                                url: "/campaigns",
                            },
                            {
                                displayName: "User groups",
                                icon: "view_list",
                                url: "/user-groups",
                            },
                        ],
                    },
                    {
                        displayName: "Mailer",
                        icon: "campaign",
                        children: [
                            {
                                displayName: "Settings",
                                icon: "toggle_on",
                                url: "/mailer/mailer-settings",
                            },
                            {
                                displayName: "Send mail",
                                icon: "mail_outline",
                                url: "/mailer/send",
                            },
                        ],
                    },
                ];
                break;
        }

        this.$nav.innerHTML = `
          <ul class="shell-sidebar-nav-root-list">
            ${navItems.map((item) => this.renderNavEntry(item)).join("")}
          </ul>
        `;

        this.$navGroups.forEach(($group) =>
            $group.removeEventListener("click", this.handleToggleGroup)
        );
        this.$links.forEach(($link) =>
            $link.removeEventListener("click", this.followLink)
        );

        this.$navGroups = Array.from(
            this.$nav.querySelectorAll(".shell-sidebar-nav-group")
        );
        this.$navGroups.forEach(($group) =>
            $group.addEventListener("click", this.handleToggleGroup)
        );

        this.$links = Array.from(
            this.$nav.querySelectorAll(".shell-sidebar-nav-link")
        );
        this.$links.forEach(($link) => {
            $link.addEventListener("click", this.followLink);
            if ($link.href == window.location.toString()) {
                SidebarService.setActiveLink($link);
                const $parents: Element[] = [];
                for (let $p = $link.parentElement; $p; $p = $p.parentElement) {
                    if ($p.classList.contains("shell-sidebar-nav-group")) {
                        $parents.push($p);
                    }
                }
                $parents.reverse().forEach(SidebarService.toggleGroup);
            }
        });
    }
}
