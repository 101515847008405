import { Axios } from "axios";
import { buildAxios } from "../helpers/axios-builder";
import { AuthService } from "./AuthService";
import { ApplicationName, Config } from "./ConfigurationService";
import { Service } from "./Service";

export type VisitSummary = {
    id: string;
    date: string;
    durationInSeconds: number;
    pageViewsCount: number;
    entryChannelName: string;
    exitChannelName: string;
}

export type VisitEvent = {
    timeStamp: string;
    channelName: string;
    pageUrl: string;
    type: string;
}

export type VisitDetail = {
    events: VisitEvent[];
}

export type ConsumerSummary = {
    fingerprint: string;
    lastVisitDate: string;
};

export type ConsumerDetail = {
    visits: VisitSummary[];
};

export class ConsumersService extends Service {
    private readonly axios: Axios;

    private tenant: Promise<string>;

    constructor(config: Config, auth: AuthService) {
        super(config);
        this.tenant = auth.getAuthorizedUser()
            .then((account) => {
                const domain = account.attributes["custom:domain"];
                const tenant = /^spheremall-(.*)-(prod|test|dev)$/.exec(domain)[1];
                return tenant;
            })
        this.axios = buildAxios(auth, `https://${config.nucleusApiDomain}`);
    }

    public async getConsumersList(): Promise<ConsumerSummary[]> {
        const tenant = await this.tenant;
        const response = await this.axios.get(`/dwh/consumers?tenant=${tenant}`);
        return response.data.data;
    }

    public async getConsumerDetail(fingerprint: string): Promise<ConsumerDetail> {
        const tenant = await this.tenant;
        const response = await this.axios.get(`/dwh/consumers/${fingerprint}?tenant=${tenant}`);
        return response.data.data;
    }

    public async getVisitDetail(id: string): Promise<VisitDetail[]> {
        const tenant = await this.tenant;
        const response = await this.axios.get(`/dwh/visits/${id}?tenant=${tenant}`);
        return response.data.data;
    }

}
